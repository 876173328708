<template>
    <b-card>
        <b-row>
            <b-col class="text-center" cols="12">
                <div class="row justify-content-center my-2">
                    <div class="col-md-4">
                        <label>From</label>
                        <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a date" local="en" initial-date="2020-06-16" class="mx-1" v-model="chartFromDate" />
                    </div>
                    <div class="col-md-4">
                        <label>To</label>
                        <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a date" local="en" initial-date="2020-06-16" class="mx-1" v-model="date" />
                    </div>
                    <div class="col-md-1" id="sub">
                        <b-button @click="onSubmit()" variant="gradient-success" class="mt-2 btn-icon">
                            <feather-icon icon="ChevronsRightIcon" />
                        </b-button>
                    </div>
                </div>
            </b-col>
            <b-col cols="12">
                <apexchart v-if="chartAlowed" class="col-md-12" type="line" height="450" :options="chartOptions" :series="series"></apexchart>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import { BFormDatepicker, BFormCheckbox, BFormSelect, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButtonGroup, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BDropdown, BDropdownItem, BBadge } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import useTrendingList from "./useTrendingList";

const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        BTab,
        BFormInput,
        BFormCheckbox,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        BBadge,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
        apexchart: VueApexCharts,
        flatPickr,
        BFormDatepicker,
        BButtonGroup,
        BDropdown,
        BDropdownItem,
    },
    directives: {
        Ripple,
    },
    setup() {
        const { fetchRealChartOptions, chartFromDate, chartToDate, date } = useTrendingList();
        const chartAlowed = ref(false);
        //methods
        onMounted(() => {
            let type = router.currentRoute.params.type;
            let id = router.currentRoute.params.id;
            fetchRealChartOptions(type, id, "2022-10-01", date.value).then((res) => {
                console.log(res);
                const date = res.data.map((item) => {
                    return item.date;
                });
                const barPoints = res.data.map((item) => {
                    return item.played_count;
                });
                const columnPoints = res.data.map((item) => {
                    return item.cumulative_count;
                });
                chartOptions.value.labels = date;
                series.value[1].data = barPoints;
                series.value[0].data = columnPoints;
                chartAlowed.value = true;
            });
        });
        const onSubmit = async () => {
            chartAlowed.value = false;
            let type = router.currentRoute.params.type;
            let id = router.currentRoute.params.id;
            fetchRealChartOptions(type, id, chartFromDate.value, date.value).then((res) => {
                console.log(res);
                const date = res.data.map((item) => {
                    return item.date;
                });
                const barPoints = res.data.map((item) => {
                    return item.played_count;
                });
                const columnPoints = res.data.map((item) => {
                    return item.cumulative_count;
                });
                chartOptions.value.labels = date;
                series.value[1].data = barPoints;
                series.value[0].data = columnPoints;
                chartAlowed.value = true;
            });
        };

        //apexchart
        const series = ref([
            {
                name: "Cumulative",
                type: "column",
                data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
            },
            {
                name: "Played Count",
                type: "area",
                data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
            },
        ]);
        const chartOptions = ref({
            chart: {
                height: 350,
                type: "line",
                stacked: false,
            },
            stroke: {
                width: [0, 2, 5],
                curve: "smooth",
            },
            plotOptions: {
                bar: {
                    columnWidth: "50%",
                },
            },

            fill: {
                opacity: [0.85, 0.25, 1],
                gradient: {
                    inverseColors: false,
                    shade: "light",
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100],
                },
            },
            labels: ["01/01/2003", "02/01/2003", "03/01/2003", "04/01/2003", "05/01/2003", "06/01/2003", "07/01/2003", "08/01/2003", "09/01/2003", "10/01/2003", "11/01/2003"],
            // labels: null,
            markers: {
                size: 0,
            },
            xaxis: {
                type: "datetime",
            },
            yaxis: {
                title: {
                    text: "Points",
                },
                min: 0,
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0) + " points";
                        }
                        return y;
                    },
                },
            },
        });
        return {
            onSubmit,
            series,
            chartOptions,
            chartFromDate,
            chartToDate,
            date,
            chartAlowed,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#sub {
    margin-top: 4px;
}
</style>
